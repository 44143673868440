//处理多窗口的浏览记录
/*
    1、每次路由改变的时候记录当前的路由，如果存在seccion里就不用管。如果不在就存在seccsion;
    2、删除操作的时候可以直接删除seccsion中的记录。
    3、关闭全部的时候，清除所有
    4、数据结构：[{id:'',name:'',path:'path'}]
    5、关闭的时候前面有主见就显示前面的一个，前面没有就显示后面的，只有一个的时候不显示关闭按钮
    6、切换语言的时候要去更新本地存储的记录，退出登录的时候要清掉。
*/
import { configStore } from '@/store/config.js'
import website from '@/config/website'

const bindTags = (e)=>{
    let tagsArray = JSON.parse(sessionStorage.getItem('tagsArray')) || [];
    if(tagsArray){
        for(let item of tagsArray){
            if(item.id === e.id ){
                return 0
            }
        }
        if(tagsArray.length === website.topNavigationNumber){//超过7个窗口，在点击新的窗口，把第7个的窗口换成最新的窗口
            for (let i = 0; i < tagsArray.length - 1; i++) {
                tagsArray[i] = tagsArray[i + 1];
              }
              tagsArray[tagsArray.length - 1] = {
                id: e.id,
                name: e.name || '',
                path: '/main'+e.path,//不能带语言参数在连接上
            };
        }else{
            tagsArray.push({
                id: e.id,
                name: e.name || '',
                path: '/main'+e.path,//不能带语言参数在连接上
            })
        }
        configStore().setTagsArray(tagsArray)
    }
}
export default bindTags