import { defineStore } from "pinia";
export const userStore = defineStore('user', {
    state: () => {
        return {
            userInfo: JSON.parse(sessionStorage.getItem('userInfo')) || {},
            isPlatform : JSON.parse(sessionStorage.getItem('userInfo'))? JSON.parse(sessionStorage.getItem('userInfo'))[0].userType:'',//是不是平台
            buttonArray: {},//按钮权限
        }
    },
    getters: {
        getUserInfo(){
            return this.userInfo
        },
        getIsPlatform(){
            if(this.userInfo[0].userType === 1){
                return true
            }else{
                return false
            }
        },
        getButtonArray(){
            return this.buttonArray
        }
    },
    actions: {
        setUserInfo(userInfo){
            this.userInfo = userInfo;
            sessionStorage.setItem('userInfo',JSON.stringify(userInfo))
        },
        setButtonArray(buttonArray){
            this.buttonArray = buttonArray
        }
    }
})