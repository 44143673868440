import { configStore } from '@/store/config.js'
import { questStore } from '@/store/quest.js'
import api_ from "@/api/index"
import { ElMessage } from 'element-plus'

export function changeLanguageFun(e,locale){//语言修改
    locale.value = e; 
    configStore().setLanguage(e)
}
export function bindLoginInfo(access_token){//登录信息的处理
    questStore().setToken(access_token)
}
export function getMeunList(callbalk){//获取路由菜单
    api_.menuApi().then(res1=>{
        if(res1.code === 0){
            questStore().setRoutes(res1.data)
            callbalk(res1.data)
        }else{
            ElMessage.warning(res1.msg)
        }
    })
}
export function getMenuListButton(call){//获取权限按钮的列表
    api_.fetchMenuTreeButton().then(res=>{
        if(res.code === 0){
            call(res.data)
        }
    })
}
export function getMenuListButtonInfo(call){//获取权限按钮的列表
    api_.getButtonInfo().then(res=>{
        if(res.code === 0){
            call(res.data)
        }
    })
}