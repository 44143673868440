<template>
    <div class="lefts">
        <div class="lefts_top">
            <img src="../../assets/icon.jpg" alt="icon">
            <div class="write-main-tit" v-if="!isCollapse">{{$t("topText.text1")}}</div>
            <i class="iconFont icon-shengchengmulu active1 iconFont1" v-if="isCollapse" @click="isCollapse = false"></i>
            <i class="iconFont icon-huanyuanmulu active1 iconFont1" v-else @click="isCollapse = true"></i>
        </div>
        <div :class="{heights:!isCollapse,default_:true}">
            <el-menu :collapse="isCollapse" :default-active="checkRouteId.checkRouteId" :unique-opened='true' v-if='menuList.length > 0'>
                <el-sub-menu :index="item.id" v-for='item in menuList' :key='item.id'>
                    <template #title>
                        <i :class="[item.icon,'iconFont iconFont2']"></i>
                        <span>{{item.label}}</span>
                    </template>
                    <template v-for='items in item.children'>
                        <template v-if='items.children && items.children.length > 0'>
                            <el-sub-menu :key='items.id' :index="items.id">
                                <template #title>
                                    <!-- <i :class="[items.icon,'iconFont iconFont2']"></i> -->
                                    <span>{{items.label}}</span>
                                </template>
                                <el-menu-item :index="itemss.id" v-for='itemss in items.children' :key='itemss.id' @click="jumpUrl(itemss)">
                                    <!-- <i :class="[itemss.icon,'iconFont iconFont2']"></i> -->
                                    {{itemss.name}}
                                </el-menu-item>
                            </el-sub-menu>
                        </template>
                        <template v-else>
                            <el-menu-item-group :key='items.id'>
                                <el-menu-item :index="items.id" @click="jumpUrl(items)"><i :class="[items.icon,'iconFont iconFont2']"></i>{{items.name}}</el-menu-item>
                            </el-menu-item-group>
                        </template>
                    </template>
                </el-sub-menu>
            </el-menu>
            <div v-else class="textNode">{{$t("getMenu.loadingMenu")}}</div>
        </div>
    </div>
</template>
<script setup>
    import { ref } from 'vue'
    // import { questStore } from '@/store/quest.js'
    import { getMeunList,getMenuListButtonInfo } from '@/commonMethod'
    import { useRouter } from 'vue-router'
    import addRoutes from '@/router/addRoutes' 
    import { configStore } from '@/store/config.js'
    import { userStore } from '@/store/user.js'
    import bindTags from '@/router/tags'
    import { defineExpose } from 'vue' 

    const checkRouteId = ref(configStore())

    const menuList = ref([])
    const router = useRouter()
    const isCollapse = ref(false)

    configStore().setCheckRouteId(router.currentRoute.value.meta.id)
    // if(questStore().getRoutes.length === 0){
    //     getMeunList((e)=>{
    //         menuList.value = e;
    //         let routes_ = addRoutes()
    //         for(let item of routes_){
    //             router.addRoute(item)
    //         }
    //     });
    // }else{
    //     menuList.value = JSON.parse(questStore().getRoutes)
    // }
    getMeunList((e)=>{//重新获取到当前的菜单
        menuList.value = e;
        let routes_ = addRoutes()
        for(let item of routes_){
            router.addRoute(item)
        }
        if(sessionStorage.getItem('tagsArray-to-update') && sessionStorage.getItem('tagsArray-to-update') === 'true'){
            let tagsArray = checkRouteId.value.getTagsArray;
            let array_ = {};//所有的路由器子组件转成对象形式 
            for(let item of routes_){
                if(item.name === checkRouteId.value.getLange){
                    switchlangMenu(tagsArray,item.children,array_)
                    break
                }
            }
            configStore().setTagsArray(tagsArray)
            sessionStorage.setItem('tagsArray-to-update',false)
        }
    });
    const changeMenu = ()=>{
        getMeunList((e)=>{//重新获取到当前的菜单
            menuList.value = e;
            let routes_ = addRoutes()
            for(let item of routes_){
                router.addRoute(item)
            }
            if(sessionStorage.getItem('tagsArray-to-update') && sessionStorage.getItem('tagsArray-to-update') === 'true'){
                let tagsArray = checkRouteId.value.getTagsArray;
                let array_ = {};//所有的路由器子组件转成对象形式 
                for(let item of routes_){
                    if(item.name === checkRouteId.value.getLange){
                        switchlangMenu(tagsArray,item.children,array_)
                        break
                    }
                }
                configStore().setTagsArray(tagsArray)
            }
        });
    }
    const switchlangMenu = (tagsArray,items,array_)=>{
        for(let item of items){
            if(item.children && item.children.length > 0){
                switchlangMenu(tagsArray,item.children,array_)
            }else{
                if(item.meta){
                    array_[item.meta.id] = item.meta.name
                }
            }
        }
        cycleSecc(tagsArray,array_)
    }
    const cycleSecc = (tagsArray,array_)=>{
        for(let item of tagsArray){
            item.name = array_[item.id]
        }
    }
    // getMenuListButton(()=>{
    //     // console.log(e)
    // })
    getMenuListButtonInfo((e)=>{
        if(e.permissions){
            userStore().setButtonArray(e.permissions)
        }
    })
    // const init = (menuList_)=>{
    //     for(let item of menuList_){
    //         if(item.id === router.currentRoute.value.meta.id){
    //             defaultActive.value = item.id
    //             return 0
    //         }else{
    //             if(item.children && item.children.length > 0){
    //                 init(item.children)
    //             }
    //         }
    //     }
    // }
    // init(menuList.value)//初始化的时候定位左边导航
  
    const jumpUrl = (e)=>{
        bindTags(e)
        router.push({ path: '/main' + e.path })
    }   
    defineExpose({ changeMenu })
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/parts.scss"; //变量样式
    @import "@/assets/scss/baseStyle/index.scss";
    .lefts{
        height: 100vh;
        position: relative;
        .textNode{
            @include display_(center);
            height: 150px;
        }
        &::after{
            content: '';
            position: absolute;right: -2px;top: 0;
            box-shadow: 2px 0 6px rgb(0 21 41 / 15%);
            height: 100%;
            width: 1px;
        }
        &_top{
            @include display_(center);
            background-color: $background-main-color;
            height: 60px;
            position: relative;
            img{
                width: 40px;height: 40px;
                border-radius: 50%;
                background: #ccc;
                margin-right: 10px;
            }
            .iconFont1{
                position: absolute;
                right: -50px;
                top: 18px;font-size: 24px;
                color: #fff;
            }
        }
        .heights{
            width: 220px;
            &::-webkit-scrollbar {
                display: none; /* Chrome Safari */
            }
        }
        .default_{
            height: calc(100vh - 60px);
            overflow: auto;
        }
        .iconFont2{
            margin-right: 5px;
        }
    }
</style>